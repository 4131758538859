<template>
  <v-app>
    <Snackbar></Snackbar>
    <div class="app-loader" v-if="isAccountsFetchWaiting && !activeAccount">
      <span class="app-loader__message">Loading your accounts...</span>
      <v-progress-circular
        class="mx-auto"
        :size="70"
        :width="7"
        indeterminate
      ></v-progress-circular>
    </div>
    <template v-else>
      <v-app-bar app color="primary" dark>
        <div v-if="$route.name =='select-account'" class="d-flex">
          <div class="d-flex align-center">
            <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="../../assets/logo-white.png"
            transition="scale-transition"
            width="25"
            />
          </div>
          <h2>Partner Portal</h2>
        </div>
        <div v-else>
        <v-btn
          text
          v-if="activeAccount"
          @click="accountSelectionPage"
          ripple
          class="px-0"
        >
        <v-avatar tile v-bind:color=" activeAccount.type === 'reseller' ? 'rgb(188,188,255)' : 'rgb(255,255,255)' " size="32" class="rounded">
            <span class="primary--text">
              {{ activeAccount.code.substr(0, 2).toUpperCase() }}
            </span>
          </v-avatar>
          <span class="ml-2">{{ activeAccount.code.toUpperCase() }}</span>
        </v-btn>
        <v-chip
          class="ma-2"
          :color="activeAccount.rechargeTokensLeft > 0 ? 'green' : 'red'"
          outlined
          text-color="white"
          v-if="activeAccount.type == 'reseller'"
        >
          Tokens left: <b>{{ activeAccount.rechargeTokensLeft }}</b>
        </v-chip>
        </div>
        <v-spacer></v-spacer>
      </v-app-bar>
      <v-main>
        <v-container fluid style="padding-bottom: 4rem">
          <router-view />
        </v-container>
      </v-main>
      <v-bottom-navigation color="primary" grow fixed>
        
        <v-btn to="/partner/home">
          <span>Partner Home</span>
          <v-icon>mdi-credit-card-outline</v-icon>
        </v-btn>

        <v-btn to="/partner/businesses">
          <span>Businesses</span>
          <v-icon>mdi-format-list-bulleted-square</v-icon>
        </v-btn>

        <!-- <v-btn to="/partner/more">
          <span>More</span>
          <v-icon>mdi-key</v-icon>
        </v-btn>-->

        <v-btn @click="openChat('Support')">
          <span>Support</span>
          <v-icon>mdi-message</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </template>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Snackbar from "../../components/Snackbar.vue";

export default {
  name: "partner",
  components: { Snackbar },
  computed: {
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn",
      activeAccount: "partner/active",
      user: "user/user",
      accountsFetchError: "partner/fetchError",
      isAccountsFetchWaiting: "partner/isFetchWaiting",
      resellerAccountList: "partner/resellerAccountList",
      wlAccountList: "partner/wlAccountList",
    }),
  },
  watch: {
    async activeAccount(val) {
      if (!val) {
        this.$router.replace("/select-account");
      } else {
        await this.fetchAccountData(this.activeAccount);
      }
    },
  },
  methods: {
    ...mapActions({
      fetchAccountData: "partner/fetchAccountData",
      clearAccountData: "partner/clearAccountData",
      fetchAccounts: "partner/fetchAccounts",
      setLogout: "user/logout",
    }),
    accountSelectionPage() {
      this.$router.push("/home");
    },
    openChat(pageName) {
      // if (window.HelpCrunch) {
      //   window.HelpCrunch("updateUserData", {
      //     currentPage: pageName,
      //   });
      //   window.HelpCrunch("openChat");
      // }
      console.log(pageName);
    },
    masterHome() {
      this.$router.replace("/master/home");
    },
    logout() {
      this.setLogout()
      this.$router.push("/login");
    },
  },
  async created() {
    // Ensure data is fetched and data matches current selected account
    if (this.activeAccount) {
      await this.fetchAccountData(this.activeAccount);
    }
  },
  beforeDestroy() {
    this.clearAccountData();
  },
};
</script>

<style>
.loader-center {
  text-align: center;
  padding: 8rem 0;
}

.container--fluid {
  max-width: 1366px;
}
</style>
